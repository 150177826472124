* ::-webkit-scrollbar {
    width: 0.6em;
}

* ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
}

* ::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.1);
    outline: 1px solid slategrey;
    border-radius: 10px;
}

html, body, #root {
    height: 100vh;
}

.emoji-picker-react {
    box-shadow: none !important;
}

.emoji-scroll-wrapper {
    overflow-x: hidden;
}

.emoji-picker-react .emoji-group:before {
    background-color: inherit !important;
}